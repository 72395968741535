@import "@fortawesome/fontawesome-free/css/all.css";
.banner{
    width: 100%;
    margin-bottom: 1%;
}
.centrado{
    text-align: center;
}
.izquierda{
    text-align: left;
}
.centradosuperior{
    text-align: center;
    margin-top: 1%;
}
.info{
    text-align: center;
    background-color: #26a53a;
    padding-top:1% ;
    color: rgb(255, 255, 255);
    padding-bottom: 1%;
}
.inscripcion{
    margin-top: 1%;
}
.temario{
    padding:2%;
}
.etiquetas{
    margin: 1%;
    display: none;
}
.oculto{
    display: none;
}
.caracteristicas{
    margin-bottom: 1%;
}
.obligatorio{
    margin-top:1%;
    color: gray;
}
.inputs{
    width: 70%;
    margin: 1%;
}
.logos{
    width: 99%;
}
.prioridades{
    width: 95%;
}
a, a:link, a:active, a:hover, a:focus, a:hover, a:visited{
    color: rgb(255, 255, 255);
}
.black{
    color:black;
}
.black:hover{
    color:#b6b6b6;
}
.send{
    margin-top: 10px;
}
.red a, a:link, a:active, a:focus, a:hover, a:visited{
    color:#000000;
}
.colorfondo{
    background: #26a53a;
}
.colortext{
    color:#26a53a;
}